import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"
import { AssetDownload } from "./utils"

import Font1Img from "../../../../assets/OldDesign/media/font_1.svg"
import Font2Img from "../../../../assets/OldDesign/media/font_2.svg"

const MediaOverviewTypography = () => {
    return (
        <div className="min-h-[300px] border-b-[1px] border-horizen-content-grey pt-4 pb-12 lg:pb-24">
            <ContentContainer>
                <h2 className="text-horizen-hp-bg text-[41px] font-bold mb-12">
                    Typography
                </h2>
                <div className="flex flex-col md:flex-row gap-y-7 gap-x-10 xl:gap-x-20">
                    <div className="md:w-1/2 space-y-6">
                        <h4 className="text-old-design-dark-blue font-bold">
                            Official Horizen Typeface
                        </h4>
                        <img src={Font1Img} alt="Typography Proxima Nova" />
                        <div className="flex justify-start md:justify-end">
                            <AssetDownload
                                href="https://fonts.adobe.com/fonts/proxima-nova"
                                text="[ Get the Font ]"
                            />
                        </div>
                    </div>
                    <div className="md:w-1/2 space-y-6">
                        <h4 className="text-old-design-dark-blue font-bold">
                            Free Alternative Typeface
                        </h4>
                        <img src={Font2Img} alt="Typography Figtree" />
                        <div className="flex justify-start md:justify-end">
                            <AssetDownload
                                href="https://fonts.google.com/specimen/Figtree?query=figtree"
                                text="[ Download Font ]"
                            />
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default MediaOverviewTypography
