import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"

import SpaceWithTaglineImg from "../../../../assets/OldDesign/media/clear_space_with_tagline_2.svg"
import SpaceSymbolImg from "../../../../assets/OldDesign/media/clear_space_symbol.svg"

interface AssetProps {
    imgUrl: string
    alt: string
    size: "small" | "big"
}

const Asset: React.FC<AssetProps> = ({ imgUrl, alt, size }) => {
    return (
        <div
            className={`flex justify-center items-center ${
                size === "big" ? "w-full lg:w-1/3" : "w-[123px]"
            }`}
            key={imgUrl}
        >
            <img className="object-cover" src={imgUrl} alt={alt} />
        </div>
    )
}

const MediaOverviewClearSpace = () => {
    return (
        <div className="min-h-[300px] border-b-[1px] border-horizen-content-grey py-12 flex items-center">
            <ContentContainer>
                <div className="flex flex-wrap justify-between space-y-6 lg:space-y-0">
                    <div className="w-full lg:w-1/3">
                        <h2 className="text-horizen-hp-bg text-[41px] font-bold">
                            Clear Space
                        </h2>
                        <p className="text-horizen-hp-bg text-[18px] font-medium">
                            Please incorporate a “clear space” equal to “H”
                            around the logo and in size of the middle circle
                            around the logo mark.
                        </p>
                    </div>
                    <Asset
                        alt="Clear space with tagline"
                        imgUrl={SpaceWithTaglineImg}
                        size="big"
                    />
                    <Asset
                        alt="Clear space symbol"
                        imgUrl={SpaceSymbolImg}
                        size="small"
                    />
                </div>
            </ContentContainer>
        </div>
    )
}

export default MediaOverviewClearSpace
