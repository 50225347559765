import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"
import { AssetDownload } from "./utils"

import LogoWithTagline2ImgSVG from "../../../../assets/OldDesign/media/logo_with_tagline_2.svg"
import LogoWithTagline2ImgPNG from "../../../../assets/OldDesign/media/logo_with_tagline_2.png"
import LogoWithTaglineDark2ImgSVG from "../../../../assets/OldDesign/media/logo_with_tagline_dark_2.svg"
import LogoWithTaglineDark2ImgPNG from "../../../../assets/OldDesign/media/logo_with_tagline_dark_2.png"
import LogoNoTaglineImgSVG from "../../../../assets/OldDesign/media/logo_no_tagline.svg"
import LogoNoTaglineImgPNG from "../../../../assets/OldDesign/media/Horizen-logo-dark.png"
import LogoNoTaglineDarkImgSVG from "../../../../assets/OldDesign/media/logo_no_tagline_dark.svg"
import LogoNoTaglineDarkImgPNG from "../../../../assets/OldDesign/media/Horizen-logo-white.png"
import LogoSymbolImgSVG from "../../../../assets/OldDesign/media/logo_symbol.svg"
import LogoSymbolImgPNG from "../../../../assets/OldDesign/media/Horizen-icon-dark.png"
import LogoSymbolDarkImgSVG from "../../../../assets/OldDesign/media/logo_symbol_dark.svg"
import LogoSymbolDarkImgPNG from "../../../../assets/OldDesign/media/Horizen-icon-white.png"

interface AssetProps {
    imgUrl: string
    alt: string
    downloadUrl?: string
    className?: string
}

const Asset: React.FC<AssetProps> = ({
    imgUrl,
    alt,
    downloadUrl,
    className,
}) => {
    return (
        <div className={`flex flex-col justify-between h-full ${className}`}>
            <img
                className="w-full h-[150px] object-cover"
                src={imgUrl}
                alt={alt}
            />

            {downloadUrl && (
                <AssetDownload href={downloadUrl} text="[ Download Logo ]" />
            )}
        </div>
    )
}

const MediaOverviewLogo = () => {
    return (
        <div className="min-h-[300px] border-b-[1px] border-horizen-content-grey pt-4 pb-12 lg:pb-24">
            <ContentContainer>
                <div className="w-full lg:w-1/2 mb-12">
                    <h2 className="text-horizen-hp-bg text-[41px] font-bold">
                        Logo
                    </h2>
                    <p className="text-horizen-hp-bg text-[18px] font-medium">
                        Please use as provided.
                        <br />
                        Do not edit, re-create, or apply filters to any of our
                        brand assets.
                    </p>
                </div>
                <div className="w-full flex flex-wrap justify-between">
                    <div className="flex flex-col space-y-4 mb-6">
                        <h4 className="text-old-design-dark-blue font-bold w-full lg:w-[450px]">
                            With tagline
                        </h4>
                        <Asset
                            alt="Dark logo with tagline"
                            imgUrl={LogoWithTaglineDark2ImgSVG}
                            downloadUrl={LogoWithTaglineDark2ImgPNG}
                            className="w-full lg:w-[450px]"
                        />
                        <Asset
                            alt="Logo with tagline"
                            imgUrl={LogoWithTagline2ImgSVG}
                            downloadUrl={LogoWithTagline2ImgPNG}
                            className="w-full lg:w-[450px]"
                        />
                    </div>
                    <div className="flex flex-col space-y-4 mb-6">
                        <h4 className="text-old-design-dark-blue font-bold w-full lg:w-[450px]">
                            Without tagline
                        </h4>
                        <Asset
                            alt="Dark logo no tagline"
                            imgUrl={LogoNoTaglineDarkImgSVG}
                            downloadUrl={LogoNoTaglineDarkImgPNG}
                            className="w-full lg:w-[450px]"
                        />
                        <Asset
                            alt="Logo no tagline"
                            imgUrl={LogoNoTaglineImgSVG}
                            downloadUrl={LogoNoTaglineImgPNG}
                            className="w-full lg:w-[450px]"
                        />
                    </div>
                    <div className="flex flex-col space-y-4 mb-6">
                        <h4 className="text-old-design-dark-blue font-bold w-[150px]">
                            Symbol
                        </h4>
                        <Asset
                            alt="Dark logo symbol"
                            imgUrl={LogoSymbolDarkImgSVG}
                            downloadUrl={LogoSymbolDarkImgPNG}
                            className="w-[150px]"
                        />
                        <Asset
                            alt="Logo symbol"
                            imgUrl={LogoSymbolImgSVG}
                            downloadUrl={LogoSymbolImgPNG}
                            className="w-[150px]"
                        />
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default MediaOverviewLogo
