import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import { AssetDownload } from "./MediaOverview/utils"

import HorizenEonLogoSVG from "../../../assets/OldDesign/media/horizen_eon_logo.svg"
import HorizenEonLogoPNG from "../../../assets/OldDesign/media/HEON_logo_dark.png"
import HorizenEonDarkLogoSVG from "../../../assets/OldDesign/media/horizen_eon_dark_logo.svg"
import HorizenEonDarkLogoPNG from "../../../assets/OldDesign/media/HEON_logo_light.png"
import HorizenEonIconSVG from "../../../assets/OldDesign/media/horizen_eon_icon.svg"
import HorizenEonIconPNG from "../../../assets/OldDesign/media/EON-icon-on-light.png"
import HorizenEonDarkIconSVG from "../../../assets/OldDesign/media/horizen_eon_dark_icon.svg"
import HorizenEonDarkIconPNG from "../../../assets/OldDesign/media/EON-icon-on-dark.png"

import CobaltLogoSVG from "../../../assets/OldDesign/media/cobalt_logo.svg"
import CobaltLogoPNG from "../../../assets/OldDesign/media/Cobalt_dark_logo 1.png"
import CobaltDarkLogoSVG from "../../../assets/OldDesign/media/cobalt_dark_logo.svg"
import CobaltDarkLogoPNG from "../../../assets/OldDesign/media/Cobalt_dark_logo 2.png"
import CobaltIconSVG from "../../../assets/OldDesign/media/cobalt_icon.svg"
import CobaltIconPNG from "../../../assets/OldDesign/media/Cobalt_dark_logo_icon 1.png"
import CobaltDarkIconSVG from "../../../assets/OldDesign/media/cobalt_dark_icon.svg"
import CobaltDarkIconPNG from "../../../assets/OldDesign/media/Cobalt_dark_logo_icon 2.png"

import HorizenAcademyLogoSVG from "../../../assets/OldDesign/media/horizen_academy_logo.svg"
import HorizenAcademyLogoPNG from "../../../assets/OldDesign/media/Horizen-Academy-logo-dark.png"
import HorizenAcademyDarkLogoSVG from "../../../assets/OldDesign/media/horizen_academy_dark_logo.svg"
import HorizenAcademyDarkLogoPNG from "../../../assets/OldDesign/media/Horizen-Academy-logo-white.png"
import HorizenAcademyIconSVG from "../../../assets/OldDesign/media/horizen_academy_icon.svg"
import HorizenAcademyIconPNG from "../../../assets/OldDesign/media/Horizen-Academy-icon-dark.png"
import HorizenAcademyDarkIconSVG from "../../../assets/OldDesign/media/horizen_academy_dark_icon.svg"
import HorizenAcademyDarkIconPNG from "../../../assets/OldDesign/media/Horizen-Academy-icon-white.png"

const assetGroups = [
    {
        name: "Horizen EON",
        logos: [
            {
                imgUrl: HorizenEonLogoSVG,
                alt: "Horizen Eon Logo",
                downloadUrl: HorizenEonLogoPNG,
                isDark: false,
            },
            {
                imgUrl: HorizenEonDarkLogoSVG,
                alt: "Horizen Eon Dark Logo",
                downloadUrl: HorizenEonDarkLogoPNG,
                isDark: true,
            },
        ],
        icons: [
            {
                imgUrl: HorizenEonIconSVG,
                alt: "Horizen Eon Icon",
                downloadUrl: HorizenEonIconPNG,
                isDark: false,
            },
            {
                imgUrl: HorizenEonDarkIconSVG,
                alt: "Horizen Eon Dark Icon",
                downloadUrl: HorizenEonDarkIconPNG,
                isDark: true,
            },
        ],
    },
    {
        name: "Cobalt",
        logos: [
            {
                imgUrl: CobaltLogoSVG,
                alt: "Cobalt Logo",
                downloadUrl: CobaltLogoPNG,
                isDark: false,
            },
            {
                imgUrl: CobaltDarkLogoSVG,
                alt: "Cobalt Dark Logo",
                downloadUrl: CobaltDarkLogoPNG,
                isDark: true,
            },
        ],
        icons: [
            {
                imgUrl: CobaltIconSVG,
                alt: "Cobalt Icon",
                downloadUrl: CobaltIconPNG,
                isDark: false,
            },
            {
                imgUrl: CobaltDarkIconSVG,
                alt: "Cobalt Dark Icon",
                downloadUrl: CobaltDarkIconPNG,
                isDark: true,
            },
        ],
    },
    {
        name: "Horizen Academy",
        logos: [
            {
                imgUrl: HorizenAcademyLogoSVG,
                alt: "Horizen Academy Logo",
                downloadUrl: HorizenAcademyLogoPNG,
                isDark: false,
            },
            {
                imgUrl: HorizenAcademyDarkLogoSVG,
                alt: "Horizen Academy Dark Logo",
                downloadUrl: HorizenAcademyDarkLogoPNG,
                isDark: true,
            },
        ],
        icons: [
            {
                imgUrl: HorizenAcademyIconSVG,
                alt: "Horizen Academy Icon",
                downloadUrl: HorizenAcademyIconPNG,
                isDark: false,
            },
            {
                imgUrl: HorizenAcademyDarkIconSVG,
                alt: "Horizen Academy Dark Icon",
                downloadUrl: HorizenAcademyDarkIconPNG,
                isDark: true,
            },
        ],
    },
]

interface AssetProps {
    assets: {
        imgUrl: string
        alt: string
        downloadUrl: string
        isDark?: boolean
    }[]
    name: string
    isIcon: boolean
}

const Asset: React.FC<AssetProps> = ({ assets, name, isIcon }) => {
    return (
        <div className="w-[350px] lg:w-auto">
            <h4 className="text-white text-[18px] font-bold mb-4 pb-2 border-b-[1px] border-[C8CFD4]">
                {name}
            </h4>
            <div className="flex flex-wrap justify-left md:justify-start gap-12">
                {assets.map((asset) => (
                    <div key={asset.alt}>
                        <div
                            className={`flex justify-center items-center ${
                                isIcon
                                    ? "w-[100px] h-[100px]"
                                    : "w-[350px] md:w-[400px] h-[100px]"
                            } ${asset.isDark ? "bg-[#0C0C1C]" : "bg-white"}`}
                        >
                            <img
                                className="object-contain"
                                src={asset.imgUrl}
                                alt={asset.alt}
                                style={
                                    isIcon
                                        ? {
                                              maxWidth: "70px",
                                              maxHeight: "70px",
                                          }
                                        : {
                                              maxWidth: "300px",
                                              maxHeight: "80px",
                                          }
                                }
                            />
                        </div>
                        <AssetDownload
                            href={asset.downloadUrl}
                            text="[ Download ]"
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

const MediaProducts = () => {
    return (
        <section className="bg-[#0C0C1C] pt-16 md:pt-36 pb-12 bg-contain bg-no-repeat bg-left-bottom bg-media-products">
            <ContentContainer>
                <div className="w-full md:w-1/2 mb-12">
                    <h2 className="text-white text-[41px] font-bold">
                        Horizen Products
                    </h2>
                    <p className="text-[#C8CFD4] text-[18px] font-medium">
                        Logo collection for your favourite Horizen products.
                    </p>
                    <p className="text-[#7A7E8C] text-[18px] font-medium">
                        If something you are looking for is not listed, please
                        contact&nbsp;
                        <a href="mailto:info@horizen.io">
                            <u>info@horizen.io</u>
                        </a>
                    </p>
                </div>
                <div />
                <div>
                    {assetGroups.map((group) => (
                        <div
                            className="w-full flex flex-wrap justify-between gap-12 mb-24"
                            key={group.name}
                        >
                            <Asset
                                assets={group.logos}
                                name={`${group.name} Logo`}
                                isIcon={false}
                            />
                            <Asset
                                assets={group.icons}
                                name={`${group.name} Icon`}
                                isIcon
                            />
                        </div>
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}

export default MediaProducts
