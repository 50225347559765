import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"
import CheckIcon from "../../../../components/Wallets/Tables/CheckIcon"
import CrossIcon from "../../../../components/Wallets/Tables/CrossIcon"

const MediaOverviewBrandName = () => {
    return (
        <div className="min-h-[300px] border-b-[1px] border-horizen-content-grey py-12 flex items-center">
            <ContentContainer>
                <div className="flex flex-wrap justify-between">
                    <div className="w-full lg:w-1/2 mb-6 lg:mb-0">
                        <h2 className="text-horizen-hp-bg text-[41px] font-bold">
                            Brand Name
                        </h2>
                        <p className="text-horizen-hp-bg text-[18px] font-medium">
                            Please avoid capitalization of a part of the name or
                            use different font weights.
                            <br /> Use sentence case or all caps.
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex space-x-12 lg:space-x-24">
                            <div className="flex items-center space-x-4">
                                <CheckIcon greenVariant />
                                <h2 className="text-horizen-hp-bg text-[27px] lg:text-[41px] font-bold w-[120px] lg:w-[180px]">
                                    Horizen
                                </h2>
                            </div>
                            <div className="flex items-center space-x-4">
                                <CheckIcon greenVariant />
                                <h2 className="text-horizen-hp-bg text-[27px] lg:text-[41px] font-bold w-[120px] lg:w-[180px]">
                                    HORIZEN
                                </h2>
                            </div>
                        </div>
                        <div className="flex space-x-12 lg:space-x-24">
                            <div className="flex items-center space-x-4">
                                <CrossIcon redVariant />
                                <h2 className="text-horizen-grey text-[27px] lg:text-[41px] font-bold w-[120px] lg:w-[180px]">
                                    HoriZen
                                </h2>
                            </div>
                            <div className="flex items-center space-x-4">
                                <CrossIcon redVariant />
                                <h2 className="text-horizen-grey text-[27px] lg:text-[41px] font-bold w-[120px] lg:w-[180px]">
                                    HoriZEN
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default MediaOverviewBrandName
