import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import Button from "../../../components/Button"
import { URLProvider } from "../../../utils/URLProvider"

const MediaHero = () => {
    return (
        <section className="min-h-[680px] mx-auto pt-[9rem] md:pt-[calc(4rem+150px)]  bg-cover bg-media-hero">
            <ContentContainer>
                <div className="text-center">
                    <h2 className="text-white mb-4 text-[60px] font-bold">
                        Brand Kit
                    </h2>
                    <h4 className="text-horizen-content-grey mb-16 text-[18px] font-medium">
                        Horizen Brand Guidelines
                    </h4>
                    <div className="w-12 h-[1px] bg-white mx-auto mb-12 lg:mb-24" />
                </div>
                <div className="flex flex-wrap mt-12 lg:mt-24 pb-16 lg:pl-8">
                    <div className="lg:w-1/2 space-y-4 mb-12 lg:mb-0">
                        <p className="text-white text-[22px] font-bold">
                            The power of the Horizen brand relies on the
                            consistent use of the brand guidelines throughout
                            all communications regardless of medium.
                        </p>
                        <p className="text-horizen-content-grey text-[18px] font-medium">
                            The following brand guidelines serve as a guide to
                            ensure that the Horizen team, community members and
                            other stakeholders are consistent in representing
                            the project’s messaging, positioning, and visual
                            identity.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2 flex justify-center">
                        <Button
                            label="DOWNLOAD FULL KIT"
                            href={URLProvider.URL_MEDIA_KIT_DOWNLOAD}
                        />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default MediaHero
