import React from "react"
import Layout from "../templates/Layout"
import MediaHelmet from "../sections/OldDesign/media/MediaHelmet"
import MediaHero from "../sections/OldDesign/media/MediaHero"
import MediaOverview from "../sections/OldDesign/media/MediaOverview"
import MediaProducts from "../sections/OldDesign/media/MediaProducts"
import NewsletterForm from "../components/NewsletterForm"

const Media = () => {
    return (
        <Layout>
            <div className="bg-horizen-dark-blue">
                <MediaHelmet />
                <MediaHero />
                <MediaOverview />
                <MediaProducts />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default Media
