import React from "react"
import Link from "../../../../components/OldDesign/Link"

const GuidelinesTitle: React.FC<{ text: string }> = ({ text }) => {
    return (
        <h3 className="font-proxima text-old-design-dark-blue font-bold uppercase text-3xl border-b border-[#e8e8e8] pb-4 mb-7 mt-16">
            {text}
        </h3>
    )
}

const GuidelinesSubtitle: React.FC<{ text: string }> = ({ text }) => {
    return (
        <h4 className="font-proxima text-old-design-dark-blue font-bold mb-4">
            {text}
        </h4>
    )
}

const AssetDetails: React.FC<{ text: string }> = ({ text }) => {
    return <p className="font-proxima text-old-design-dark-blue mt-1">{text}</p>
}

const AssetDownload: React.FC<{ href: string; text?: string }> = ({
    href,
    text = "Download",
}) => {
    return (
        <p className="mt-1 flex justify-end">
            <Link download href={href} newTab>
                {text}
            </Link>
        </p>
    )
}

export { GuidelinesTitle, GuidelinesSubtitle, AssetDetails, AssetDownload }
