import React from "react"
import { Helmet } from "react-helmet"

const MediaHelmet = () => {
    return (
        <Helmet>
            <title>Horizen Brand Kit</title>
            <meta property="og:title" content="Horizen Brand Kit" />
            <meta name="twitter:title" content="Horizen Brand Kit" />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="The Horizen Brand Kit is a guide to ensure proper representation of the Horizen brand regardless of the medium."
            />
            <meta
                property="og:description"
                content="The Horizen Brand Kit is a guide to ensure proper representation of the Horizen brand regardless of the medium."
            />
            <meta
                name="twitter:description"
                content="The Horizen Brand Kit is a guide to ensure proper representation of the Horizen brand regardless of the medium."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/brand-kit.jpeg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/brand-kit.jpeg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="horizen media kit, zen media, zencash media, horizen brand, zen cash media"
            />
            <link rel="canonical" href="https://www.horizen.io/media/" />
            <meta property="og:url" content="https://www.horizen.io/media/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
            <script type="application/ld+json" />
        </Helmet>
    )
}

export default MediaHelmet
