import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"
import ColorBrandKit from "../../../../components/Media/ColorBrandKit"

const MediaOverviewColors = () => {
    return (
        <div className="min-h-[300px] border-b-[1px] border-horizen-content-grey pt-12 pb-12 lg:pb-24 flex items-center">
            <ContentContainer>
                <div>
                    <h2 className="text-horizen-hp-bg text-[41px] font-bold mb-12">
                        Colors
                    </h2>
                    <div className="flex flex-wrap space-y-4 justify-between">
                        <div className="flex items-center space-x-2">
                            <ColorBrandKit color="#0C0C1C" />
                            <div className="text-[18px] text-horizen-hp-bg">
                                <p>Deep dark blue</p>
                                <p>#0c0c1c</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <ColorBrandKit color="#041742" />
                            <div className="text-[18px] text-horizen-hp-bg">
                                <p>Intelligent navy blue</p>
                                <p>#041742</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <ColorBrandKit color="#0e9de5" />
                            <div className="text-[18px] text-horizen-hp-bg">
                                <p>Freedom sky blue</p>
                                <p>#0e9de5</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <ColorBrandKit color="#26db8d" />
                            <div className="text-[18px] text-horizen-hp-bg">
                                <p>Robust bright green</p>
                                <p>#26db8d</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default MediaOverviewColors
