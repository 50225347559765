import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import MediaOverviewBrandName from "./MediaOverview/MediaOverviewBrandName"
import MediaOverviewLogo from "./MediaOverview/MediaOverviewLogo"
import MediaOverviewClearSpace from "./MediaOverview/MediaOverviewClearSpace"
import MediaOverviewTypography from "./MediaOverview/MediaOverviewTypography"
import MediaOverviewColors from "./MediaOverview/MediaOverviewColors"
import MediaOverviewBackgrounds from "./MediaOverview/MediaOverviewBackgrounds"
import Button from "../../../components/Button"
import { URLProvider } from "../../../utils/URLProvider"

const MediaOverview = () => {
    return (
        <section className="bg-[#F1F5F8]">
            <ContentContainer>
                <MediaOverviewBrandName />
                <MediaOverviewLogo />
                <MediaOverviewClearSpace />
                <MediaOverviewTypography />
                <MediaOverviewColors />
                <MediaOverviewBackgrounds />
                <div className="flex justify-center pb-24">
                    <Button
                        label="DOWNLOAD FULL KIT"
                        customClass="!text-[#041742]"
                        href={URLProvider.URL_MEDIA_KIT_DOWNLOAD}
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default MediaOverview
