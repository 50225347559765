import React from "react"

interface ColorBrandKitProps {
    color?: string
}

const ColorBrandKit: React.FC<ColorBrandKitProps> = ({ color = "#0C0C1C" }) => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 15.0197V10.0008L15.0197 10.0008M15.0197 70.4791H10.0001L10.0001 65.4594M65.4594 10.0001L70.478 10V15.0197M70.4781 65.4593V70.4784H65.4594"
                stroke={color}
                strokeWidth="0.5"
            />
            <circle cx="40.2383" cy="40.2393" r="16.5" fill={color} />
            <g filter="url(#filter0_d_4526_838)">
                <circle
                    cx="40.2383"
                    cy="40.2393"
                    r="23"
                    stroke={color}
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_4526_838"
                    x="0.738281"
                    y="0.739258"
                    width="79"
                    height="79"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4526_838"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4526_838"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default ColorBrandKit
