import React from "react"
import ContentContainer from "../../../../templates/ContentContainer"
import { AssetDownload } from "./utils"

import BackgroundDark1Img from "../../../../assets/BrandKit/ZBF_brand_background_1.jpg"
import BackgroundDark2Img from "../../../../assets/BrandKit/ZBF_brand_background_2.jpg"
import BackgroundLight1Img from "../../../../assets/BrandKit/ZBF_brand_background_9.jpg"
import BackgroundLight2Img from "../../../../assets/BrandKit/ZBF_brand_background_10.jpg"

import BackgroundDark5Img from "../../../../assets/BrandKit/ZBF_brand_background_5.jpg"
import BackgroundDark6Img from "../../../../assets/BrandKit/ZBF_brand_background_6.jpg"
import BackgroundDark7Img from "../../../../assets/BrandKit/ZBF_brand_background_7.jpg"
import BackgroundDark8Img from "../../../../assets/BrandKit/ZBF_brand_background_8.jpg"

interface AssetT {
    imgUrl: string
    alt: string
    downloadUrl?: string
}

const assetsRow1: AssetT[] = [
    {
        imgUrl: BackgroundDark1Img,
        alt: "Dark background",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_1.jpg`,
    },
    {
        imgUrl: BackgroundDark2Img,
        alt: "Dark background 2",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_2.jpg`,
    },
    {
        imgUrl: BackgroundLight1Img,
        alt: "Light background",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_9.jpg`,
    },
    {
        imgUrl: BackgroundLight2Img,
        alt: "Light background 2",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_10.jpg`,
    },
]

const assetsRow2: AssetT[] = [
    {
        imgUrl: BackgroundDark5Img,
        alt: "Dark background 5",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_5.jpg`,
    },
    {
        imgUrl: BackgroundDark6Img,
        alt: "Dark background 6",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_6.jpg`,
    },
    {
        imgUrl: BackgroundDark7Img,
        alt: "Dark background 7",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_7.jpg`,
    },
    {
        imgUrl: BackgroundDark8Img,
        alt: "Dark background 8",
        downloadUrl: `https://downloads.horizen.io/file/web-assets/Background/ZBF_brand_background_8.jpg`,
    },
]

const Asset: React.FC<AssetT> = ({ alt, downloadUrl, imgUrl }) => {
    return (
        <div className="flex-grow">
            <img className="w-full lg:h-[240px]" src={imgUrl} alt={alt} />

            {downloadUrl && (
                <AssetDownload href={downloadUrl} text="[ Download ]" />
            )}
        </div>
    )
}

const MediaOverviewBackgrounds = () => {
    return (
        <div className="min-h-[300px] pt-12 pb-24 flex items-center">
            <ContentContainer>
                <div>
                    <h2 className="text-horizen-hp-bg text-[41px] font-bold mb-12">
                        Backgrounds
                    </h2>
                    <div className="flex flex-col lg:flex-row gap-6 mb-6">
                        {assetsRow1.map((asset) => (
                            <Asset
                                key={asset.imgUrl}
                                imgUrl={asset.imgUrl}
                                alt={asset.alt}
                                downloadUrl={asset.downloadUrl}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col md:flex-row gap-6">
                        {assetsRow2.map((asset) => (
                            <Asset
                                key={asset.imgUrl}
                                imgUrl={asset.imgUrl}
                                alt={asset.alt}
                                downloadUrl={asset.downloadUrl}
                            />
                        ))}
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default MediaOverviewBackgrounds
